/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

 (function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages

        // $(document).ready(function() {

        //   AOS.init({
        //       //startEvent: "load",
        //       disable: 'mobile',
        //       offset: 200,
        //       duration: 1200,
        //       once: true 
        //     }); 

        //   var rellax = new Rellax('.js-rellax', { 
        //      //center: true
        //    }); 

        // });


        // (function fadeHeaderOnScroll() {

        //   $(window).scroll(function() {
        //     var scrollTop = window.pageYOffset || document.documentElement.scrollTop;
        //     if (scrollTop > 100) {

        //       $('.site-header').addClass('is-scrolling');
        //     }
        //     else {

        //       $('.site-header').removeClass('is-scrolling');
        //     }
        //   }).scroll()

        // })();


        (function scrollToContent() {

          var $menu = $("#jsScroll");

          $menu.on("click","a", function(){
              var $this = $(this),
                  href = $this.attr("href"),
                  topY = $(href).offset().top;

              TweenLite.to($(window), 1, {
                  scrollTo:{
                      y: topY-110,
                      autoKill: true
                  },
                  ease:Power3.easeOut
               });

            return false;
          });

        })();        



        (function setDeviceClass(){

          $(document).ready(function() {

            $tablet = 768;
            $desktop = 1281;

            if($(window).width() < $tablet) {
              $("html").addClass("mobile");
            }
            else if($(window).width() < $desktop) {
              $("html").addClass("tablet");
            }
            else {
              $("html").addClass("desktop"); 
            }

          });

        })();


        // var openCloseMenu = function() {

        //   var html = $('html');
        //   var openTrigger = $('.menu-main-open');
        //   var closeTrigger = $('.menu-main-close');
        //   var menuLogo = $('.icon-logo');
        //   var menuContainer = $('.menu-main');
        //   var menuLeft = $('.menu-main-primary');
        //   var menuRight = $('.menu-main-right');
        //   var menuItem = $( '.menu-main a');

        //   //TL
        //   var tlOpenMenu = new TimelineMax({paused: true});
        //   var tlCloseMenu = new TimelineMax({paused: true});

        //   //OPEN TIMELINE
        //   tlOpenMenu.set(html, {className: '+=is-main-menu-open'})
        //     .add("preOpen")
        //     .fromTo(menuLeft, 0.6, {
        //     y: 40, opacity: 0, visibility: 'hidden'
        //   }, {
        //     y: 0, opacity: 1, visibility: 'visible', ease: Power4.easeOut
        //   }, "-=0.2")
        //   .fromTo(menuRight, 0.6, {
        //     y: -40, opacity: 0, visibility: 'hidden'
        //   }, {
        //     y: 0, opacity: 1, visibility: 'visible', ease: Power4.easeOut
        //   }, "-=0.5");

        //   //CLOSE TIMELINE
        //   tlCloseMenu.add("close")
        //     .to(menuLeft, 0.6, {
        //     y: 40, opacity: 0, ease: Power4.easeOut, onComplete: function() {
        //       menuLeft.css('visibility','hidden');
        //     }
        //   }, "close")
        //   .to(menuRight, 0.6, {
        //     y: -40, opacity: 0, ease: Power4.easeOut, onComplete: function() {
        //       menuRight.css('visibility','hidden');
        //     }
        //   }, "close").set(html, {className: '-=is-main-menu-open'}); 

        //   //EVENTS
        //   openTrigger.on('click', function(){ 
        //     if(tlOpenMenu.progress() < 1){
        //       tlOpenMenu.play();
        //         } else {
        //             tlOpenMenu.restart();
        //             console.log('menu opened');
        //         }
        //   });
                 
        //   closeTrigger.on('click', function(){
        //     if(tlCloseMenu.progress() < 1){
        //       tlCloseMenu.play();
        //         } else {
        //             tlCloseMenu.restart();
        //             console.log('menu closed');
        //         }
        //   });

        //   menuItem.on('click', function(){
        //     tlCloseMenu.play();
        //   });

        // };

        // openCloseMenu();

        // var openCloseForm = function() {

        //   $('.js-toggle-form:not(.-inited)').click(function () {
        //     if ($('html').hasClass('is-main-menu-open')) {
        //       $('html').toggleClass('is-main-menu-open');
        //       $('html').toggleClass('is-form-open');
        //     } else {
        //       $('html').toggleClass('is-form-open');
        //     }
        //     setTimeout(function () {
        //       $('.c-form_wrap').animate({ scrollTop: 0 }, 0);
        //     }, 600);

        //     if (!$('html').hasClass('is-form-open')) {
        //       setTimeout(function () {
        //         $('.c-form_wrap').animate({ scrollTop: 0 }, 0);
        //       }, 0);
        //     }

        //     return false;
        //   }).addClass("-inited");

        // };

        // openCloseForm();


        (function scrollToMenu() {

          var $menu = $("#js-scroll-menu");

          $menu.on("click","a", function(){
            var $this = $(this),
            href = $this.attr("href"),
            topY = $(href).offset().top;

            TweenLite.to($(window), 1, {
              scrollTo:{
                y: topY-100,
                autoKill: true
              },
              ease:Power3.easeOut
            });

            return false;
          });

        })();    




    // // initially fire isotope layout
    // var $grid = $('.grid').isotope({
    //   itemSelector: '.grid-item',
    //   transitionDuration: 0,
    //   masonry: {
    //     columnWidth: 100
    //   }
    // });

    // // runs isotope reload on facetwp change
    // $(document).on('facetwp-loaded', function() {
    //   $grid.isotope('reloadItems').isotope();
    // });

        (function initMenu() {

          var body = $('body');
          var menu = $('.site-menu');

          $('#burger:not(.-inited)').on('click', function () {

              $(body).toggleClass('menu-is-open');
              $(menu).toggleClass('site-menu--show');

            setTimeout(function () {
              $('.site-menu').animate({ scrollTop: 0 }, 0);
            }, 600);

            if (!$(body).hasClass('menu-is-open')) {
              setTimeout(function () {
                $('.site-menu').animate({ scrollTop: 0 }, 0);
              }, 0);
            }
          }).addClass("-inited");

        })(); 


        (function facetRefresh() {

          var $facetResults = $('.facetwp-template');

            $(document).on('facetwp-refresh', function() {
                TweenLite.to($facetResults, 0.2, {opacity:"0.10", delay:0.1, ease:Power2.easeOut});
            });
            $(document).on('facetwp-loaded', function() {
                TweenLite.to($facetResults, 0.2, {opacity:"1", delay:0.1, ease:Power2.easeOut});
            });

        })();


        (function facetTextWrapper() {

          $(document).on('facetwp-loaded', function() {

            var projectTypes = $('.facetwp-radio');

            $(projectTypes)
            .contents()
            .filter(function() {
              return this.nodeType === 3;
            })
            .wrap( "<div class='facetwp-radio-text'></div>" )
            .end()
            .filter( "br" )
            .remove();

          });

        })();


        // $("#burger").click(function() {
        //   $(this).toggleClass("-is-open"), $("header.site-header").toggleClass("-is-open").toggleClass("no-cursor"), $("#site-navigation").toggleClass("-is-open"), $(".curtain").toggleClass("-is-open"), window.setTimeout(function() {
        //     $("#site-navigation").toggleClass("-is-hidden");
        //   }, 500);
        // });


        //Toggle Nav
        // $('.js-toggle-nav:not(.-inited)').on('click', function () {
        //     //.c-nav_wrap
        //     if ($('body').hasClass('has-category-open')) {
        //       $('body').toggleClass('has-category-open');
        //     } else if ($('body').hasClass('has-search-open')) {
        //       $('body').toggleClass('has-search-open');
        //       $('body').toggleClass('has-nav-open');
        //     } else {
        //       $('body').toggleClass('has-nav-open');
        //     }
            
        //     setTimeout(function () {
        //       $('.c-nav_wrap').animate({ scrollTop: 0 }, 0);
        //     }, 600);

        //     if (!$('body').hasClass('has-nav-open')) {
        //       setTimeout(function () {
        //         $('.c-nav_wrap').animate({ scrollTop: 0 }, 0);
        //       }, 0);
        //     }
        //   }).addClass("-inited");


        // $("#menu-toggle").click(function() {
        //   $(this).toggleClass("-is-open"), $("header.site-header").toggleClass("-is-open").toggleClass("no-cursor"), $("#site-navigation").toggleClass("-is-open"), $(".curtain").toggleClass("-is-open"), window.setTimeout(function() {
        //     $("#site-navigation").toggleClass("-is-hidden");
        //   }, 500);
        // });

        // Accordion

        (function initAccordion() {

          $('.js-accordion:not(.-inited)').click(function () {
            setTimeout(function () {
              $(document).trigger('SmoothScroll.rebuild');
            }, 400);

            if ($(this).hasClass('is-open')) {
              $(this).removeClass('is-open').next().slideUp(300);
            } else {
              if ($('.js-accordion.is-open').length) {
                $('.js-accordion.is-open').removeClass('is-open').next().slideUp(300);
              }
              $(this).addClass('is-open').next().slideDown(300);
              var _this = $(this); // scope baby
              setTimeout(function () {
                $('html, body').animate({
                  scrollTop: $(_this).offset().top - 160
                }, 500);
              }, 300);
            }
            return false;
          }).addClass("-inited");

        })();


        (function initSliders() {

          $(document).ready(function() {

            $('.carousel-default').flickity({
              // options
              cellSelector: '.carousel-cell',
              wrapAround:true,
              autoPlay:7000,
              cellAlign: 'center',
              pageDots:false,
              prevNextButtons:true,
              draggable:true,
              dragThreshold:3,
              setGallerySize: false,
              selectedAttraction: 0.025,
              friction: 0.28,          
              contain: true,
              imagesLoaded: true,
              lazyLoad:true,
              freeScroll: true,
              freeScrollFriction: .075,
              listenSelect: true,
              listenSettle: true,
              listenScroll: true,
              listenDragStart: true,
              listenDragMove: true,
              listenDragEnd: true,
              listenPointerDown: true,
              listenPointerMove: true,
              listenPointerUp: true,
              listenStaticClick: true,
              listenLazyLoad: true,
              listenBgLazyLoad: true

            });


            $('.carousel-main').flickity({
              // options
              cellSelector: '.carousel-cell',
              wrapAround:true,
              autoPlay:5000,
              cellAlign: 'center',
              pageDots:false,
              prevNextButtons:false,
              draggable:false,
              dragThreshold:3,
              setGallerySize: false,
              selectedAttraction: 0.025,
              friction: 0.28,          
              contain: true,
              imagesLoaded: true,
              lazyLoad:true,
              freeScroll: true,
              freeScrollFriction: .075,
              listenSelect: true,
              listenSettle: true,
              listenScroll: true,
              listenDragStart: true,
              listenDragMove: true,
              listenDragEnd: true,
              listenPointerDown: true,
              listenPointerMove: true,
              listenPointerUp: true,
              listenStaticClick: true,
              listenLazyLoad: true,
              listenBgLazyLoad: true

            });

            $('.carousel-main_controller').flickity({
              asNavFor: '.carousel-main',
              cellSelector: '.carousel-cell-nav',
              contain: true,
              autoPlay:false,          
              cellAlign: 'center',
              prevNextButtons:false,
              controls: 'counter-label',
              setGallerySize: true,
              resize: true,
              percentPosition: false,
              pageDots: false
            });

            $('.carousel-irregular').flickity({
              // options
              cellSelector: '.carousel-cell',
              wrapAround:true,
              autoPlay:7000,
              cellAlign: 'center',
              pageDots:false,
              prevNextButtons:true,
              draggable:true,
              dragThreshold:3,
              setGallerySize: false,
              selectedAttraction: 0.025,
              friction: 0.28,          
              contain: true,
              imagesLoaded: true,
              lazyLoad:true,
              freeScroll: true,
              freeScrollFriction: .075,
              listenSelect: true,
              listenSettle: true,
              listenScroll: true,
              listenDragStart: true,
              listenDragMove: true,
              listenDragEnd: true,
              listenPointerDown: true,
              listenPointerMove: true,
              listenPointerUp: true,
              listenStaticClick: true,
              listenLazyLoad: true,
              listenBgLazyLoad: true

            });

            $('.carousel-irregular_controller').flickity({
              asNavFor: '.carousel-irregular',
              cellSelector: '.carousel-cell-nav',
              contain: true,
              autoPlay:false,          
              cellAlign: 'center',
              prevNextButtons:false,
              controls: 'counter-label',
              setGallerySize: true,
              resize: true,
              percentPosition: false,
              pageDots: false
            });

          });
          

        })();



      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }


  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.


// Sticky Scroll Nav

// const navSections = document.querySelector('.js-scroll-sections');
// const nav = document.querySelector('.js-scroll-nav');
// const topOfNav = navSections.offsetTop; 
// const site = document.querySelector('.js-scroll-container');


// function fixNav() {
//   if(window.scrollY >= topOfNav) {
//     console.log(topOfNav, window.scrollY);
//     console.log(nav.offsetHeight);
//     document.body.classList.add('fixed-nav');
//     site.style.paddingTop = nav.offsetHeight + 'px';
    
//   } else {
//     document.body.classList.remove('fixed-nav');
//     site.style.paddingTop = 0;
//   }
// }
// window.addEventListener('scroll', fixNav); 

// var flkty = new Flickity('.carousel-default');
// var carouselStatus = document.querySelector('.carousel-basic-counter-progress');
// var carouselTotal = document.querySelector('.carousel-basic-counter-total');

// function updateStatus() {
//   var slideNumber = flkty.selectedIndex + 1;
//   carouselStatus.textContent = slideNumber + '/';
//   carouselProgress.textContent = slideNumber + flkty.slides.length;
// }
// updateStatus();

// flkty.on( 'select', updateStatus );

